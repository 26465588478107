import React from 'react'

import Layout from '../components/layout'
import Head from '../components/head'

import linkStyles from '../components/link.module.scss'

import textStyles from '../components/text.module.scss'
import indexboxStyles from '../components/indexbox.module.scss'

const AboutPage = () => {
    return (
        <Layout>
           <Head title="Contact -"/>
           <div className={indexboxStyles.divbox}>
        <p className={textStyles.text}>Contact
        <br/><a href="mailto:riko.pinders@web.de" className={linkStyles.link}>riko.pinders@web.de</a>
        <br/>
        <br/>
        About
        <br/>Riko Pinders is an information- and communicationdesign student of the Rhine-Waal University of Applied Science.
        He is currently based in Sonsbeck, Germany. He is open for commissions or collaberations.
        So feel free to get in contact.
        <br/>
        <br/>
        Social
        <br/><a href="https://www.linkedin.com/in/riko-pinders/" target="blank" className={linkStyles.link}>LinkedIn</a>
        <br/>        
        <a href="https://www.behance.net/rikopinders" target="blank" className={linkStyles.link}>Behance</a>
        <br/>
        <a href="https://dribbble.com/rikopinders" target="blank" className={linkStyles.link}>Dribbble</a>
        <br/>
        <a href="https://www.facebook.com/riko.pinders" target="blank" className={linkStyles.link}>Facebook</a>
        <br/>
        <a href="https://www.instagram.com/rikopinders/" target="blank" className={linkStyles.link}>Instagram</a>
        <br/>
        <a href="https://twitter.com/PindersRiko" target="blank" className={linkStyles.link}>Twitter</a>
        <br/>
        <a href="https://www.pinterest.de/rikopinders/boards/" target="blank" className={linkStyles.link}>Pinterest</a>
        <br/>
        <a href="https://www.tumblr.com/blog/rikopinders" target="blank" className={linkStyles.link}>Tumblr</a>
        <br/>
        <a href="https://ello.co/rikopinders" target="blank" className={linkStyles.link}>Ello</a>
        <br/>
        <a href="https://medium.com/@rikopinders" target="blank" className={linkStyles.link}>Medium</a>
        <br/>
        <a href="https://www.xing.com/profile/Riko_Pinders/cv" target="blank" className={linkStyles.link}>Xing</a>
        </p>
        </div>
        </Layout>
    )
}

export default AboutPage